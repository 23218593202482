import React from "react";
import { graphql, Link } from "gatsby";
// import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

import Seo from "../components/seo";
import { EmptyState } from "../components/feedback";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";

import Logo from "../images/logos/PayHero/PayHero_Full.svg";
import Logo_White from "../images/logos/PayHero/PayHero_Full_White.svg";

const ImageBox = styled(Box)`
  &:before {
    content: "";
    width: 130%;
    padding-bottom: 130%;
    background-color: ${(props) => props.theme.colours.blue};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;

export const query = graphql`
  query($slug: String!) {
    contentfulDownloadableContent(url: { eq: $slug }) {
      url
      title
      description
      content {
        raw
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
      type
      dripFormId
    }
  }
`;

const Download = (props) => {
  // let [success, setForm] = useState(false);
  // let [loading, setLoading] = useState(false);
  // let [error, setError] = useState("");
  // let [recaptchaChecked, setRecaptchaChecked] = useState(false);

  // const recaptchaRef = React.createRef();

  // const onChange = (value) => {
  //   if (value) {
  //     setRecaptchaChecked(true);
  //     setError("");
  //   }
  // };

  // const handleSubmit = () => {
  //   setError("");
  //   setLoading(true);
  //   const recaptchaValue = recaptchaRef.current.getValue();

  //   if (!recaptchaValue) {
  //     setError("Please complete the Google reCAPTCHA.");
  //     setLoading(false);
  //     return;
  //   } else {
  //     setTimeout(function() {
  //       setForm(true);
  //       setLoading(false);
  //     }, 2000);
  //   }
  // };

  return (
    <Layout fullPage>
      <Seo
        title={`${props.data.contentfulDownloadableContent.title} | PayHero`}
        description={props.data.contentfulDownloadableContent.description}
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={15}>
          <Link css={{ display: "table" }} to="/blog">
            <Row justify="flex-start" stackGap={10} alignCenter noBreak>
              <img src={Logo} alt="PayHero" css={{ maxWidth: "150px" }} />
              <h4
                css={{
                  borderLeft: "solid 1px #ddd",
                  padding: "5px 0 5px 10px",
                  color: "#999",
                }}
                className="-fontNormal"
              >
                Resources
              </h4>
            </Row>
          </Link>
        </Wrapper>
      </Container>
      <Container bg="Secondary">
        <Wrapper>
          <Row stackGap={80} alignCenter>
            <Box size={40} stackGap={40} centerOnMobile>
              <Box className="font-bump" stackGap={10}>
                <div className="badge -lg -blue">
                  {props.data.contentfulDownloadableContent.type}
                </div>
                <h1 css={{ color: "#fff" }}>
                  {props.data.contentfulDownloadableContent.title}
                </h1>
                <h4 css={{ color: "#fff" }}>
                  {props.data.contentfulDownloadableContent.description}
                </h4>
              </Box>
              <EmptyState
                show
                message={[
                  <span key={0}>
                    Sorry, this payslip template is no longer available. To
                    easily create & send accurate, branded payslips check out{" "}
                  </span>,
                  <Link key={1} to="/">
                    PayHero
                  </Link>,
                  <span key={2}>.</span>,
                ]}
              />
              {/* <div size={40}>
                <Box stackGap={30}>
                  <iframe
                    name="stopRedirect"
                    id="stopRedirect"
                    css={{ display: "none" }}
                    title="PayHero | Submit Form"
                  ></iframe>
                  <Error message={error} />
                  <Loader show={loading} white />
                  {!success && (
                    <>
                      <form
                        className={`${loading ? "-isLoading" : ""} -smallStack`}
                        data-drip-embedded-form={
                          props.data.contentfulDownloadableContent.dripFormId
                        }
                        action={`${
                          recaptchaChecked
                            ? "https://www.getdrip.com/forms/" +
                              props.data.contentfulDownloadableContent
                                .dripFormId +
                              "/submissions"
                            : ""
                        }`}
                        target="stopRedirect"
                        method="POST"
                        onSubmit={handleSubmit}
                      >
                        <input
                          placeholder="First Name"
                          type="text"
                          name="fields[first_name]"
                          required
                        />
                        <input
                          placeholder="Last Name"
                          type="text"
                          name="fields[last_name]"
                          required
                        />
                        <input
                          placeholder="Email Address"
                          type="email"
                          name="fields[email]"
                          required
                        />
                        <ReCAPTCHA
                          className="-center recaptcha"
                          ref={recaptchaRef}
                          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHASITEKEY}
                          onChange={onChange}
                        />
                        <button className="blue">
                          Download{" "}
                          {props.data.contentfulDownloadableContent.type}
                        </button>
                      </form>
                    </>
                  )}
                  <Success
                    show={success}
                    className="download-success"
                    title="Thanks!"
                    message={
                      `This ` +
                      props.data.contentfulDownloadableContent.type +
                      ` is on it's way to your inbox.`
                    }
                  />
                </Box>
              </div> */}
            </Box>
            <ImageBox size={40}>
              <GatsbyImage
                image={
                  props.data.contentfulDownloadableContent.image.gatsbyImageData
                }
                alt={props.data.contentfulDownloadableContent.title}
                className="-addShadow -rounded -center"
              />
            </ImageBox>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="#092833">
        <Wrapper stackGap={40}>
          <Row className="-whiteOut" stackGap={30} alignCenter>
            <Link css={{ display: "table" }} to="/blog">
              <Row justify="flex-start" stackGap={10} alignCenter noBreak>
                <img
                  src={Logo_White}
                  alt="PayHero"
                  css={{ maxWidth: "120px" }}
                />
                <p
                  css={{
                    borderLeft: "solid 1px #fff",
                    paddingLeft: "10px",
                    color: "#fff",
                    marginBottom: "0",
                  }}
                  className="-fontNormal"
                >
                  Resources
                </p>
              </Row>
            </Link>
            <Box centerOnMobile>
              <Row stackGap={30} justify="flex-end">
                <Link to="/blog">Resource Library</Link>
                <Link to="/contact">Get In Touch</Link>
              </Row>
            </Box>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Download;
